export const environment = {
  production: false,
  staging: true,
  apiUrl: 'https://api.staging.services4eo.com/request',
  authority: 'https://triple-a.staging.services4eo.com',
  clientId: '1d4dae5e-7543-4215-ae42-d8328a09bcfa',
  scope: 'profile openid email user_name gsc4eo',
  geoServerUrl: 'https://webgeo2.hidrografico.pt/geoserver/ows',
  apiKey: '4de969a4-083d-48aa-aa22-27478ee8b28b',
  paypal_clientID: 'AZ5-jh2EW9Mm6Lyrns15sQfUfWqg3jehR2sEv3vnVs6xWakUawUsopPlkRB8iyo6BoJvN8Flh_hr8eXu',
  spaceChainApiKey: 'LQ6aXfsKKR9u1WxgcELMJYAY6XNzxpN5cueOZa35',
  spaceChainWalletURL: 'https://082cd9qz7d.execute-api.eu-west-2.amazonaws.com/dev',
  deimosWalletAddress: '0x0CdA49DF14D116442c54D37cf7f08B855F289128',
  //? External links
  externalLinks: {
    deimos: 'https://elecnor-deimos.com',
    esa: 'https://www.esa.int',
    support: 'https://service4eo.atlassian.net/servicedesk/customer/portal/9',
    ipma: 'https://www.ipma.pt/en/index.html',
    cls: 'https://www.cls.fr/en/',
    air: 'https://www.aircentre.org/',
    nova: 'https://www.novasbe.unl.pt/en/',
    terra: 'https://www.terradue.com/portal/',
    sintef_ocean: 'https://www.sintef.no/sintef-ocean/',
    sintef_nord: 'https://www.sintef.no/en/',
    pml: 'https://pml.ac.uk/',
    salmon: 'https://www.salmonscotland.co.uk/',
    acores:'https://portal.azores.gov.pt/',
    i2s:'https://www.i2s.gr/',
    linkedin:'https://www.linkedin.com/company/nextocean-eo/about/',
    twitter:'https://twitter.com/nextocean_eo',
    requestAreaUrl:'https://service4eo.atlassian.net/servicedesk/customer/portal/9/group/44/create/133',
    youtube:'https://www.youtube.com/@nextoceanEO',
    amn: 'https://www.amn.pt/Paginas/Homepage.aspx',
    colabAtlantic: 'https://colabatlantic.com/'
  },

  cms: {
    url: 'https://cms4eo.staging.services4eo.com',
    api: 'https://cms4eo.staging.services4eo.com/api',
    website: 'nextocean',
  },
  downloadAllHelp:{
    url:'https://en.wikipedia.org/wiki/Comparison_of_download_managers'
  },
  geoportalUrl: 'https://nextocean-portal.staging.services4eo.com/home',
  outputFilterPattern: '_PML_FMS__SHP',
  analyticsID: 'G-LC7X3BWGD4'
};
